import Swal from "sweetalert2";
import { Request } from "../../helpers/api";
import { parseInvoiceErrors } from "../../helpers/utils";

export const handleInvoice = async (cfdiObject, postCfdiCallback, cfdiName) => {
  let errorsList = [];
  cfdiObject?.forEach(
    ({
      createdPdf,
      invoiced,
      remitted,
      nameService,
      msgInvoiced,
      generatedPaymentComplement,
    }) => {
      const completedInvoice =
        createdPdf && (invoiced || remitted || generatedPaymentComplement);
      if (!completedInvoice) {
        errorsList.push({ nameService, msgInvoiced });
      }
    }
  );
  const allInvoiced = errorsList.length === 0;
  Swal.fire({
    title: allInvoiced ? "Éxito" : "Error",
    html: !allInvoiced
      ? `${errorsList.map(
          (e, index) =>
            "<b>" +
            (cfdiName || e.nameService || `Error ${index + 1}`) +
            "</b>: " +
            parseInvoiceErrors(e.msgInvoiced)
        )}`
      : "Operación exitosa",
    icon: allInvoiced ? "success" : "warning",
  }).then(() => {
    postCfdiCallback();
  });
};

export const handleCancelCfdi = (
  singleData,
  epData,
  afterSubmit,
  alertInfo = {
    text: "Cancelación de factura",
    onSuccessMessage: "Factura cancelada con éxito.",
  }
) => {
  const cfdiId = singleData[epData.cfdiId];
  Swal.fire({
    title: "¿Confirma la siguiente operación?",
    text: alertInfo.text,
    icon: "warning",
    showCancelButton: true,
  }).then(async ({ isConfirmed }) => {
    if (isConfirmed) {
      epData.setLoading && epData.setLoading(true);

      const { ok } = await Request(
        epData.endPoint + cfdiId,
        epData.epBody,
        "PATCH"
      );
      epData.setLoading && epData.setLoading(false);
      if (ok) {
        Swal.fire("Éxito", alertInfo.onSuccessMessage, "success").then(
          afterSubmit()
        );
      }
    }
  });
};

export const handleRebill = async (
  preRebillResponseObject,
  onSuccessCallback,
  handleLoading,
  errorMessage
) => {
  const {
    ok,
    cancelAndRelateInvoice,
    objupdateafacturar = [],
    idServiceDetailCfdi,
  } = preRebillResponseObject;
  if (objupdateafacturar[0]?.invoiced && ok && cancelAndRelateInvoice) {
    handleLoading(true);
    const { ok: okRelateInvoice, msg } = await Request(
      `/invoice/relate/detailService/${idServiceDetailCfdi}`,
      {},
      "PATCH"
    );
    handleLoading(false);
    if (okRelateInvoice) {
      const errorsList = [];
      const { createdPdf, invoiced, remitted, nameService, msgInvoiced } =
        objupdateafacturar[0];
      const completedInvoice = createdPdf && (invoiced || remitted);
      if (!completedInvoice) {
        errorsList.push({ nameService, msgInvoiced });
      }
      const allInvoiced = errorsList.length === 0;

      Swal.fire({
        title: allInvoiced ? "Éxito" : "Error",
        html: !allInvoiced
          ? `${errorsList.map(
              (e) =>
                "<b>" +
                e.nameService +
                "</b>" +
                ": " +
                parseInvoiceErrors(e.msgInvoiced) +
                "<br><br>"
            )}`
          : msg,
        icon: allInvoiced ? "success" : "warning",
      }).then(({ isConfirmed, isDismissed }) => {
        if (!errorsList.length && (isConfirmed || isDismissed)) {
          onSuccessCallback();
        }
      });
    }
  } else Swal.fire("Error", errorMessage, "error");
};
